import { apiSlice } from 'api/apiSlice';
import IGroup from 'types/group/IGroup';
import IQueryParameters from 'types/IQueryParameters';
import PaginatedResult from 'types/PaginatedResult';
import IApplication from 'types/application/IApplication';
import IApplicationJob from 'types/application/IApplicationJob';
import IApplicationMaintainer from 'types/application/IApplicationMaintainer';
import IPermission from 'types/application/IPermission';
import IApplicationRole from 'types/application/IApplicationRole';
import { IApplicationMicroservice } from 'types/application/microservice/IApplicationMicroservice';
import { Microserviceable, MicroserviceType } from 'types/application/microservice/MicroserviceType';
import IOrganization from 'types/organization/IOrganization';
import ITrustedSource from 'types/application/ITrustedSource';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({

    addApplication: builder.mutation({
      query: data => ({ url: 'applications', method: 'post', data }),
      invalidatesTags: () => [{ type: 'Applications' }],
    }),

    getApplication: builder.query<IApplication, string>({
      query: (id: string) => ({ url: `applications/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'Applications', id }],
    }),

    listApplications: builder.query<PaginatedResult<IApplication>, IQueryParameters>({
      query: (queryParameters: IQueryParameters) => {
        return { url: 'applications', method: 'get', queryParameters };
      },
    }),

    getApplicationActivity: builder.query({
      query: ({ queryParameters, applicationId }) => {
        return { url: `applications/${applicationId}/activity`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationActivity'],
    }),

    editApplication: builder.mutation({
      query: data => ({ url: `applications/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Applications', id }],
    }),

    deleteApplication: builder.mutation({
      query: data => ({ url: `applications/${data.id}`, method: 'delete', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Applications', id }],
    }),

    regenerateApplicationCredentials: builder.mutation({
      query: id => ({ url: `applications/${id}/client-credentials`, method: 'put' }),
    }),

    getApplicationGroups: builder.query<PaginatedResult<IGroup>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/groups`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationGroups'],
    }),

    getApplicationRoles: builder.query<PaginatedResult<IApplicationRole>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/roles`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationRoles'],
    }),

    getApplicationTrustedSources: builder.query<PaginatedResult<ITrustedSource>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/trusted-sources`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationRoles'],
    }),

    getApplicationPermissions: builder.query<PaginatedResult<IPermission>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/permissions`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationPermissions'],
    }),

    addApplicationGroups: builder.mutation<IGroup[], { applicationId: string, data: string[] }>({
      query: ({ applicationId, data }: { applicationId: string, data: string[] }) => ({ url: `applications/${applicationId}/groups`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationGroups' }],
    }),

    removeApplicationGroup: builder.mutation({
      query: ({ applicationId, groupId }) => ({ url: `applications/${applicationId}/groups/${groupId}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'ApplicationGroups' }],
    }),

    addApplicationMaintainers: builder.mutation<IApplicationMaintainer[], { applicationId: string, data: string[] }>({
      query: ({ applicationId, data }: { applicationId: string, data: string[] }) => ({ url: `applications/${applicationId}/maintainers`, method: 'post', data }),
      invalidatesTags: () => ['ApplicationMaintainers'],
    }),

    getApplicationMaintainers: builder.query<PaginatedResult<IApplicationMaintainer>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }) => {
        return { url: `applications/${applicationId}/maintainers`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationMaintainers'],
    }),

    removeApplicationMaintainer: builder.mutation<null, { applicationId: string, maintainerId: string }>({
      query: ({ applicationId, maintainerId }) => ({ url: `applications/${applicationId}/maintainers/${maintainerId}`, method: 'delete' }),
      invalidatesTags: () => ['ApplicationMaintainers'],
    }),

    editApplicationMaintainerRole: builder.mutation<IApplicationMaintainer, { applicationId: string, maintainerId: string, data: { maintainer_role: string } }>({
      query: ({ applicationId, maintainerId, data }) => ({ url: `applications/${applicationId}/maintainers/${maintainerId}`, method: 'put', data }),
      invalidatesTags: () => ['ApplicationMaintainers'],
    }),

    getApplicationJobs: builder.query<PaginatedResult<IApplicationJob>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/jobs`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationJobs'],
    }),

    getApplicationMicroservices: builder.query<PaginatedResult<IApplicationMicroservice<MicroserviceType, Microserviceable>>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/microservices`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationMicroservices'],
    }),

    getApplicationOrganizations: builder.query<PaginatedResult<IOrganization>, { queryParameters: IQueryParameters, applicationId: string }>({
      query: ({ queryParameters, applicationId }: { queryParameters: IQueryParameters, applicationId: string }) => {
        return { url: `applications/${applicationId}/organizations`, method: 'get', queryParameters };
      },
      providesTags: ['Organizations'],
    }),

    addApplicationOrganizations: builder.mutation<IApplication, { applicationId: string, data: string[] }>({
      query: ({ applicationId, data }: { applicationId: string, data: string[] }) => ({ url: `applications/${applicationId}/organizations`, method: 'post', data }),
      invalidatesTags: () => [{ type: 'Organizations' }],
    }),

    removeApplicationOrganization: builder.mutation<IApplication, { applicationId: string, organizationId: string }>({
      query: ({ applicationId, organizationId }) => ({ url: `applications/${applicationId}/organizations/${organizationId}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'Organizations' }],
    }),

    storeApplicationIconImg: builder.mutation<IApplication, { applicationId: string, data: FormData }>({
      query: ({ applicationId, data }: { applicationId: string, data: FormData }) => ({
        url: `applications/${applicationId}/icon-img`, method: 'post', formData: data, headers: {},
      }),
      invalidatesTags: () => [{ type: 'Applications' }],
    }),

    removeApplicationIconImg: builder.mutation<null, string>({
      query: (applicationId: string) => ({
        url: `applications/${applicationId}/icon-img`,
        method: 'delete',
      }),
      invalidatesTags: () => [{ type: 'Applications' }],
    }),
  }),
});

export const {
  useAddApplicationMutation,
  useAddApplicationGroupsMutation,
  useAddApplicationMaintainersMutation,
  useGetApplicationQuery,
  useLazyListApplicationsQuery,
  useLazyGetApplicationActivityQuery,
  useDeleteApplicationMutation,
  useEditApplicationMutation,
  useEditApplicationMaintainerRoleMutation,
  useLazyGetApplicationGroupsQuery,
  useGetApplicationGroupsQuery,
  useLazyGetApplicationRolesQuery,
  useLazyGetApplicationPermissionsQuery,
  useGetApplicationPermissionsQuery,
  useGetApplicationMaintainersQuery,
  useLazyGetApplicationMaintainersQuery,
  useRemoveApplicationGroupMutation,
  useRemoveApplicationMaintainerMutation,
  useRegenerateApplicationCredentialsMutation,
  useLazyGetApplicationJobsQuery,
  useLazyGetApplicationMicroservicesQuery,
  useLazyGetApplicationOrganizationsQuery,
  useAddApplicationOrganizationsMutation,
  useRemoveApplicationOrganizationMutation,
  useStoreApplicationIconImgMutation,
  useRemoveApplicationIconImgMutation,
  useGetApplicationTrustedSourcesQuery,
  useLazyGetApplicationTrustedSourcesQuery,
} = extendedApiSlice;
