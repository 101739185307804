import { Suspense } from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import {
  Spinner,
} from "@apex/react-toolkit/components";
import { translate } from '@apex/react-toolkit/lib';
import PageNotFound from 'common/PageNotFound';
import InfrastructureHome from 'App/AppRoutes/Infrastructure/Dashboard';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import New from 'App/AppRoutes/Infrastructure/NodeGroups/New';
import NodeGroupDetail from 'App/AppRoutes/Infrastructure/NodeGroups/Detail';
import EditNodeGroup from 'App/AppRoutes/Infrastructure/NodeGroups/Edit';
import Unauthorized from 'common/Unauthorized';
import NewKubernetesRoleConfig from 'App/AppRoutes/Infrastructure/KubernetesRoles/New';
import KubernetesRoleConfigDetail from 'App/AppRoutes/Infrastructure/KubernetesRoles/Detail';
import EditKubernetesRoleConfig from 'App/AppRoutes/Infrastructure/KubernetesRoles/Edit';


const Infrastructure = () => {
  const { isSuperUser, isLoading } = useCheckUserPermission();

  if (isLoading) return <Spinner />;
  if (!isSuperUser) return <Unauthorized />;

  return (
    <Suspense fallback={<h1>{translate('loading')} . . .</h1>}>
      <Routes>
        <Route path="/" element={<InfrastructureHome />} />
        <Route path="/node-groups/new" element={<New />} />
        <Route path="/node-groups/:nodeGroupId" element={<NodeGroupDetail />} />
        <Route path="/node-groups/:nodeGroupId/edit" element={<EditNodeGroup />} />
        <Route path="/kubernetes-roles/new" element={<NewKubernetesRoleConfig />} />
        <Route path="/kubernetes-roles/:kubernetesRoleId" element={<KubernetesRoleConfigDetail />} />
        <Route path="/kubernetes-roles/:kubernetesRoleId/edit" element={<EditKubernetesRoleConfig />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Infrastructure;
