import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { formControlLocalToIsoOut, formControlIsoToLocalIn } from 'helpers/datetime';

const ApplicationMicroserviceDeploymentSearchFields = (props) => {
  const {
    disabled,
    fromDate,
    searchStatus,
    searchTargetEnvironment,
    setFromDate,
    setSearchStatus,
    setSearchTargetEnvironment,
    setToDate,
    take,
    toDate,
    setTake,
  } = props;
  return (
    <Row>
      <Col md={3}>
        <Form.Group>
          <Form.Label>From</Form.Label>
          <Form.Control
            onChange={e => setFromDate(
              formControlLocalToIsoOut(e.target.value),
            )}
            value={formControlIsoToLocalIn(fromDate)}
            type="datetime-local"
          />
        </Form.Group>
      </Col>
      <Col md={3}>
        <Form.Group>
          <Form.Label>To</Form.Label>
          <Form.Control
            onChange={e => setToDate(
              formControlLocalToIsoOut(e.target.value),
            )}
            value={formControlIsoToLocalIn(toDate)}
            type="datetime-local"
          />
        </Form.Group>
      </Col>
      <Col md={2} className="text-start">
        <Form.Label>{translate('targetEnvironment')}</Form.Label>
        <Form.Select
          size="sm"
          onChange={e => setSearchTargetEnvironment(e.target.value)}
          disabled={disabled}
          value={searchTargetEnvironment}
        >
          <option value="">{translate('any')}</option>
          <option value="dev">dev</option>
          <option value="staging">staging</option>
          <option value="prod">prod</option>
        </Form.Select>
      </Col>
      <Col md={2} className="text-start">
        <Form.Label>{translate('status')}</Form.Label>
        <Form.Select
          size="sm"
          onChange={e => setSearchStatus(e.target.value)}
          disabled={disabled}
          value={searchStatus}
        >
          <option value="">{translate('any')}</option>
          <option value="COMPLETE">COMPLETE</option>
          <option value="CREATED">CREATED</option>
          <option value="IN_PROGRESS">IN_PROGRESS</option>
          <option value="FAILED">FAILED</option>
        </Form.Select>
      </Col>
      <Col md={2}>
        <Form.Group>
          <Form.Label>{translate('resultsPerPage')}</Form.Label>
          <Form.Control as="select" size="sm" value={take} onChange={(e) => setTake(+e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Form.Control>
        </Form.Group>
      </Col>
    </Row>
  );
};

ApplicationMicroserviceDeploymentSearchFields.propTypes = {
  disabled: PropTypes.bool,
  searchStatus: PropTypes.string.isRequired,
  searchTargetEnvironment: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
  fromDate: PropTypes.string.isRequired,
  setSearchStatus: PropTypes.func.isRequired,
  setSearchTargetEnvironment: PropTypes.func.isRequired,
  setToDate: PropTypes.func.isRequired,
  setFromDate: PropTypes.func.isRequired,
  take: PropTypes.number.isRequired,
  setTake: PropTypes.func.isRequired,
};

ApplicationMicroserviceDeploymentSearchFields.defaultProps = {
  disabled: false,
};

export default ApplicationMicroserviceDeploymentSearchFields;
