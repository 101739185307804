import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { Spinner } from '@apex/react-toolkit/components';
import useSearchApplicationMicroserviceDeployments from 'hooks/useSearchApplicationMicroserviceDeployments';
import useInterval from 'hooks/useInterval';
import { REFETCH_DELAY_IN_SECONDS } from 'config';
import ApplicationMicroserviceDeploymentPaginatedTable from '../ApplicationMicroserviceDeploymentPaginatedTable';
import ApplicationMicroserviceDeploymentSearchFields from '../ApplicationMicroserviceDeploymentSearchFields';

const ApplicationMicroserviceDeploymentSearchTable = ({ microserviceId, isDbMicroservice, canPromote }) => {
  const {
    result: queryResult,
    take,
    setTake,
    setPage,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    searchStatus,
    setSearchStatus,
    setSearchTargetEnvironment,
    searchTargetEnvironment,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    refetch,
  } = useSearchApplicationMicroserviceDeployments({ microserviceId });

  useInterval(() => {
    refetch();
  }, REFETCH_DELAY_IN_SECONDS * 1000);

  if (queryResult.isLoading || !queryResult.data) {
    return (
      <Spinner />
    );
  }

  const { data, meta } = queryResult.data;

  return (
    <Card className="bg-dark">
      <Card.Header>
        <Row>
          <Col>
            <h3>Deployments</h3>
          </Col>
          <Col className="text-end">
            <small className="text-muted">Results update every {REFETCH_DELAY_IN_SECONDS} seconds</small>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <ApplicationMicroserviceDeploymentSearchFields
              searchTargetEnvironment={searchTargetEnvironment}
              setSearchTargetEnvironment={setSearchTargetEnvironment}
              searchStatus={searchStatus}
              setSearchStatus={setSearchStatus}
              toDate={toDate}
              setToDate={setToDate}
              fromDate={fromDate}
              setFromDate={setFromDate}
              take={take}
              setTake={setTake}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ApplicationMicroserviceDeploymentPaginatedTable
              setSortBy={(updatedSortKey) => {
                if (sortBy === updatedSortKey) {
                  setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
                } else {
                  setSortBy(updatedSortKey);
                }
              }}
              sortBy={sortBy}
              setSortDirection={setSortDirection}
              sortDirection={sortDirection}
              applicationMicroserviceDeployments={data}
              pageCount={meta.last_page}
              currentPage={meta.current_page}
              onPageChange={setPage}
              isDbMicroservice={isDbMicroservice}
              canPromote={canPromote}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ApplicationMicroserviceDeploymentSearchTable.propTypes = {
  microserviceId: PropTypes.string.isRequired,
  isDbMicroservice: PropTypes.bool,
  canPromote: PropTypes.bool,
};

ApplicationMicroserviceDeploymentSearchTable.defaultProps = {
  isDbMicroservice: false,
  canPromote: false,
};

export default ApplicationMicroserviceDeploymentSearchTable;
