import React, { ReactElement } from 'react';
import { Link, useParams } from 'react-router-dom';
import InteractiveTable from 'common/InteractiveTable';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import { capitalize } from 'lodash';
import { IOrganizationMember, OrganizationRole } from 'types/organization/IOrganization';
import { SortBy } from 'hooks/useSearchOrganizationMembers';
import { SortDirection } from 'types/ListSearchTypes';
import { InteractiveTableColumn } from 'common/InteractiveTable';

const memberRoles = [
  'member',
  'admin',
];

const OrganizationMembersTable: React.FC<{
  disabled: boolean;
  sortBy: SortBy;
  setSortBy: (sortBy: SortBy) => void;
  sortDirection: SortDirection;
  organizationMembers: IOrganizationMember[];
  setSelectedMember: (selectedMember: IOrganizationMember) => void;
  onSelectMemberRole: (member: IOrganizationMember, role: OrganizationRole) => void;
  actionColumn?: InteractiveTableColumn<IOrganizationMember>;
  canPromote: boolean;
  canRemove: boolean;
}> = ({
  disabled,
  organizationMembers,
  sortBy,
  setSortBy,
  sortDirection,
  setSelectedMember,
  onSelectMemberRole,
  actionColumn,
  canPromote,
  canRemove
}) => {
    const { orgId } = useParams();
    const columns: InteractiveTableColumn<IOrganizationMember>[] = [
      {
        title: translate('firstName'),
        dataIndex: 'first_name',
        sortDirection: sortBy === 'first_name' && sortDirection,
        onHeader: () => ({
          onClick: () => setSortBy('first_name'),
          className: 'clickable',
        }),
        render: (first: string, { id }: IOrganizationMember): ReactElement => (<Link to={`/organizations/${orgId}/users/${id}`}>{first}</Link>),
      },
      {
        title: translate('lastName'),
        dataIndex: 'last_name',
        sortDirection: sortBy === 'last_name' && sortDirection,
        onHeader: () => ({
          onClick: () => setSortBy('last_name'),
          className: 'clickable',
        }),
      },
      {
        title: translate('email'),
        dataIndex: 'email',
        sortDirection: sortBy === 'email' && sortDirection,
        onHeader: () => ({
          onClick: () => setSortBy('email'),
          className: 'clickable',
        }),
      },
      {
        title: translate('role'),
        dataIndex: 'role',
        render: (role: string, member: IOrganizationMember): ReactElement => {
          if (!canPromote) return (<span>{role}</span>);

          return (
            <DropdownButton
              size="sm"
              variant="secondary"
              title={capitalize(member.organization_role)}
              onSelect={(eventKey) => onSelectMemberRole(member, eventKey as OrganizationRole)}
              menuVariant="dark"
              disabled={disabled}
            >
              {
                memberRoles.map(item => (
                  <Dropdown.Item
                    key={item}
                    eventKey={item}
                    active={item === role}
                    as="button"
                  >
                    {capitalize(item)}
                  </Dropdown.Item>
                ))
              }
            </DropdownButton>
          )
        },
      },

    ];

    if (canRemove) columns.push({
      title: '',
      dataIndex: 'id',
      render: (_: string, member: IOrganizationMember): ReactElement => (
        <div className="text-center">
          <Button
            variant="danger"
            disabled={disabled}
            size="sm"
            onClick={() => setSelectedMember(member)}
          >
            <FontAwesomeIcon icon="times" fixedWidth /> {translate('remove')}
          </Button>
        </div>
      ),
    },)

    if (actionColumn) columns.push(actionColumn);

    return (
      <InteractiveTable
        dataId="id"
        data={organizationMembers}
        columns={columns}
      />
    );
  };

export default OrganizationMembersTable;
