export default {
  // application microservice job types
  'create-database-password': 'Create Database Password',
  'create-ecr-repository': 'Create ECR repository',
  'create-microservice-ecr-repo': 'Create ECR repository',
  'create-replication-config': 'Create Replication Config',
  'create-replication-endpoints': 'Create Replication Endpoints',
  'delete-microservice-ecr-repo': 'Delete Ecr Respository',
  'delete-microservice-from-database': 'Delete Microservice',
  'delete-replication-config': 'Delete Replication Config',
  'install-pg-logical': 'Install pglogical',
  'start-replication': 'Start Replication',
  'stop-replication': 'Stop Replication',
  'update-database-connection-secret': 'Update Database Connection Secret',
};
