import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import FormFeedback from 'common/FormFeedback';
import IRoleConfig, { IRoleConfigValidationErrors, IRoleRule } from 'types/infrastructure/IRoleConfig';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetKubernetesApiGroupsQuery, useLazyGetResourcesByApiGroupQuery } from 'api/roleConfigsSlice';
import { KubernetesAPIResource } from 'types/infrastructure/KubernetesResources';

export type RoleConfigFormValues = Omit<IRoleConfig, 'id' | 'created_at' | 'updated_at' | 'rules'> & {
  rules: IRoleRule[] | RoleRuleFormValues[]
};
export type RoleRuleFormValues = Omit<IRoleRule, 'id' | 'created_at' | 'updated_at'>;
export type FetchedResources = { [key: string]: KubernetesAPIResource[] };

const defaultValues: RoleConfigFormValues = {
  name: '',
  description: '',
  type: 'role',
  rules: [],
};

const RoleConfigForm: FC<{
  initialValues?: IRoleConfig | RoleConfigFormValues
  disabled: boolean
  onCancel: () => void
  onSubmit: (formData: RoleConfigFormValues) => void
  apiErrors: IRoleConfigValidationErrors | null
  submitText: string
  icon?: IconProp
}> = ({ initialValues = defaultValues, disabled, onCancel, onSubmit, apiErrors, submitText, icon = 'plus' }) => {
  const [type, setType] = useState<'role' | 'clusterrole'>(initialValues.type);
  const [rules, setRules] = useState<IRoleRule[] | RoleRuleFormValues[] | []>(initialValues.rules);
  const [fetchedResources, setFetchedResources] = useState<FetchedResources>({});
  const [resourcesError, setResourcesError] = useState<{ [key: string]: string }>({});

  const schema = yup.object({
    type: yup.string().required(translate('typeRequired')).oneOf(['role', 'clusterrole']),
    name: yup.string().required(translate('nameRequired')),
    description: yup.string().required(translate('nameRequired')),
  });

  const {
    data: kubernetesPaths,
    isLoading: loadingKubernetesPaths,
    error: kubernetesPathsError
  } = useGetKubernetesApiGroupsQuery();
  const [getResources] = useLazyGetResourcesByApiGroupQuery();

  const fetchResourcesForApiGroup = async (apiGroupPath: string) => {
    try {
      const response = await getResources({ apiGroupPath });
      if (response.data) {
        setFetchedResources({
          ...fetchedResources,
          [apiGroupPath]: response.data,
        });
      }
    } catch (e) {
      setResourcesError({
        ...resourcesError,
        [apiGroupPath]: translate('unableToGetResourcesForGivenApiGroupAtThisTime'),
      })
    }
  };

  // if editing an existing role config, load up all the data from k8s
  // so we can pre-populate the form.
  useEffect(() => {
    if (initialValues && initialValues.rules) {
      (async () => {
        let resourceList: { [key: string]: KubernetesAPIResource[] } = {};
        for (const rule of initialValues.rules) {
          const resource = await getResources({ apiGroupPath: rule.api_group });

          if (resource && resource.data) {
            resourceList = {
              ...resourceList,
              [rule.api_group]: resource.data,
            }
          }
        }

        setFetchedResources(resourceList);
      })()
    }
  }, []);

  if (kubernetesPathsError) return <Error />;
  if (loadingKubernetesPaths) return <Spinner />;

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(formInput) => {
        // @ts-expect-error I have new defined as an optional so I don't know why it's complaining
        rules.forEach(r => delete r.new);
        const formData: RoleConfigFormValues = {
          name: formInput.name,
          description: formInput.description,
          type: type,
          rules,
        };

        onSubmit(formData);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        values,
        errors,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formRoleType">
            <Form.Label>{translate('roleType')}</Form.Label>
            <Form.Select
              value={type}
              onChange={(e) => setType(e.target.value as 'role' | 'clusterrole')}
              onBlur={handleBlur}
            >
              <option value="" disabled>{translate('selectRoleType')}</option>
              <option value="role">{translate('role')}</option>
              <option value="clusterrole">{translate('clusterRole')}</option>
            </Form.Select>
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="type"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{translate('name')}</Form.Label>
            <Form.Control
              required
              name="name"
              type="text"
              placeholder={translate('name')}
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.name && !errors.name}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="name"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>{translate('description')}</Form.Label>
            <Form.Control
              required
              name="description"
              type="text"
              placeholder={translate('description')}
              value={values.description}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={disabled}
              isValid={touched.description && !errors.description}
            />
            <FormFeedback
              touched={touched}
              errors={errors}
              apiErrors={apiErrors}
              fieldName="description"
            />
          </Form.Group>

          <Card className="p-3 bg-dark-secondary">
            <Card.Header>
              <h3>{translate('rules')}</h3>
            </Card.Header>
            {
              rules.map((rule: IRoleRule | RoleRuleFormValues, ruleIndex: number) => (
                <Card bg="dark" className="mb-3" key={`rule-${ruleIndex}`}>
                  <Card.Body>
                    <Form.Group className="mb-3" controlId="formRoleName">
                      <Form.Label>{translate('name')}</Form.Label>
                      <Form.Control
                        required
                        name="roleName"
                        type="text"
                        placeholder={translate('name')}
                        value={rule.name}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const copy = JSON.parse(JSON.stringify(rules));
                          copy[ruleIndex].name = e.target.value;
                          setRules(copy);
                        }}
                        disabled={disabled}
                        isValid={touched.name && !errors.name}
                      />
                      <FormFeedback
                        touched={touched}
                        errors={errors}
                        apiErrors={apiErrors}
                        fieldName="name"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formApiGroup">
                      <Form.Label>{translate('apiGroup')}</Form.Label>
                      <Form.Select
                        value={rule.api_group}
                        onChange={(e) => {
                          const copy = JSON.parse(JSON.stringify(rules));
                          copy[ruleIndex].api_group = e.target.value;
                          copy[ruleIndex].resources = [];
                          setRules(copy);
                          fetchResourcesForApiGroup(e.target.value);
                        }}
                        placeholder={translate('selectApiGroup')}
                      >
                        <option value='' disabled>{translate('selectApiGroup')}</option>
                        {
                          // @ts-expect-error need to define a kubernetes API Group type
                          kubernetesPaths && kubernetesPaths.map(path => <option key={path.path} value={path.path}>{path.name}</option>)
                        }
                      </Form.Select>
                      <FormFeedback
                        touched={touched}
                        errors={errors}
                        apiErrors={apiErrors}
                        fieldName="api_group"
                      />
                    </Form.Group>

                    {
                      rule.api_group && (
                        <Form.Group className="mb-3" controlId="formApiGroup">
                          <Form.Label>{translate('resources')}</Form.Label>
                          {
                            fetchedResources[rule.api_group] ? (
                              fetchedResources[rule.api_group].map((resource: KubernetesAPIResource, resourceIndex: number) => (
                                <div key={`resource-${resourceIndex}`}>
                                  <Form.Check
                                    onChange={(e) => {
                                      const copy = JSON.parse(JSON.stringify(rules));
                                      if (e.target.checked) {
                                        copy[ruleIndex].resources.push({
                                          name: resource.name,
                                          verbs: [],
                                        });
                                      } else {
                                        copy[ruleIndex].resources.splice(copy[ruleIndex].resources.indexOf(resource));
                                      }
                                      setRules(copy);
                                    }}
                                    type="checkbox"
                                    onBlur={handleBlur}
                                    checked={!!rule.resources.find(r => r.name === resource.name)}
                                    name="searchOrCreate"
                                    label={resource.name}
                                  />
                                  {
                                    rule.resources.find(r => r.name === resource.name) && resource && resource.verbs && resource.verbs.map((verb: string) => {
                                      const ruleRoleIndex = rule.resources.findIndex(r => r.name === resource.name);
                                      return (
                                        <Form.Check
                                          key={`resource-${resource.name}-verb`}
                                          className="ms-4"
                                          onChange={(e) => {
                                            const copy = JSON.parse(JSON.stringify(rules));
                                            if (e.target.checked) {
                                              copy[ruleIndex].resources[ruleRoleIndex].verbs.push(verb);
                                            } else {
                                              copy[ruleIndex].resources[ruleRoleIndex].verbs.splice(copy[ruleIndex].resources[ruleRoleIndex].verbs.indexOf(verb), 1);
                                            }
                                            setRules(copy);
                                          }}
                                          type="checkbox"
                                          onBlur={handleBlur}
                                          checked={!!rule.resources[ruleRoleIndex].verbs.find(v => v === verb)}
                                          name="searchOrCreate"
                                          label={verb}
                                        />
                                      )
                                    })
                                  }
                                </div>
                              ))) : (
                              <Spinner />
                            )
                          }
                        </Form.Group>
                      )
                    }
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      variant="danger"
                      type="button"
                      disabled={disabled}
                      onClick={() => {
                        // splice is in-place, so we remove the item
                        rules.splice(ruleIndex, 1);
                        // and then we copy it with slice(0)
                        const copy = JSON.parse(JSON.stringify(rules));
                        setRules(copy);
                      }}
                      className="mb-2"
                    >
                      <FontAwesomeIcon icon="times" className="me-1" />
                      {translate('remove')}
                    </Button>
                  </Card.Footer>
                </Card>
              ))
            }

            <Card.Footer>
              <Button
                variant="primary"
                type="button"
                disabled={disabled}
                onClick={() => setRules([...rules, {
                  name: '',
                  api_group: '',
                  resources: [],
                }])}
                className="me-2"
              >
                <FontAwesomeIcon icon="plus" className="me-1" />
                {translate('addAnotherRule')}
              </Button>
            </Card.Footer>
          </Card>

          <Row className="mt-4">
            <Col>
              <Button
                variant="secondary"
                type="button"
                disabled={disabled}
                onClick={onCancel}
                className="me-2"
              >
                <FontAwesomeIcon icon="ban" className="me-1" />
                {translate('cancel')}
              </Button>
              <Button variant="primary" type="submit" disabled={disabled}>
                {
                  disabled ? (
                    <FontAwesomeIcon icon="spinner" className="me-1" spin />
                  ) : (
                    <FontAwesomeIcon icon={icon} className="me-1" />
                  )
                }
                {submitText}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default RoleConfigForm
