import React from 'react';
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import HttpPortForm from 'common/applicationMicroservice/HttpPortForm';
import AccordionToggleLink from 'common/AccordionToggleLink';
import { IApplicationMicroserviceApi } from 'types/application/microservice/IApiMicroservice';
import DangerZone from './DangerZone';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';

const Configuration: React.FC<{
  isDeveloperAuthorized: boolean
  isSuperUserAuthorized: boolean
  microservice: IApplicationMicroserviceApi
}> = ({ microservice, isDeveloperAuthorized, isSuperUserAuthorized }) => {
  return (
    <Accordion alwaysOpen>
      <Card bg="dark">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('environmentVariables')}
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.environment_variables_count} {translate('environmentVariables')}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="0"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText={translate('close')}
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="0">
        <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} />
      </Accordion.Collapse>
      <Card bg="dark" className="mt-3">
        <Card.Header>
          <Row>
            <Col className="text-start">
              {translate('httpPort')}
            </Col>
            <Col>
              <span className="text-muted">
                {microservice.microserviceable.http_port}
              </span>
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey="1"
                inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                activeText={translate('close')}
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey="1">
        <HttpPortForm
          microserviceId={microservice.id}
          httpPort={microservice.microserviceable.http_port}
          isDeveloperAuthorized={isDeveloperAuthorized}
        />
      </Accordion.Collapse>

      { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }

      { // Danger zone
        isDeveloperAuthorized && (
          <>
            <Card bg="dark" className="mt-3">
              <Card.Header>
                <Row>
                  <Col className="text-start text-danger">
                    {translate('dangerZone')}
                  </Col>
                  <Col className="text-end">
                    <AccordionToggleLink
                      eventKey="3"
                      inactiveText={translate('open')}
                      activeText={translate('close')}
                    />
                  </Col>
                </Row>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey="3">
              <DangerZone microservice={microservice} />
            </Accordion.Collapse>
          </>
        )
      }
    </Accordion>
  );
};

export default Configuration;
