import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import Configuration from 'App/AppRoutes/Applications/Application/Microservices/Detail/ApiDetail/Configuration';
import ApplicationMicroserviceHealthCheckStatus from 'common/applicationMicroservice/ApplicationMicroserviceHealthCheckStatus';
import { ApplicationMicroserviceApiPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useHealthcheck from 'hooks/useHealthcheck';
import styles from './ApiDetail.module.css';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import LogsTab from 'common/computeBoundMicroservices/LogsTab';

const ApiDetail = ({ microservice }) => {
  const { applicationId } = useParams();
  const { microserviceable: apiMicroservice } = microservice;
  const isHealthy = useHealthcheck(microservice.id, 'prod');
  const { isSuperUser } = useCheckUserPermission();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
                {(isHealthy !== null) && (
                  <span className={`ms-3 align-middle ${styles.healthCheckStatusContainer}`}>
                    <ApplicationMicroserviceHealthCheckStatus isHealthy={isHealthy} />
                  </span>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('route')}:</strong> <span>{apiMicroservice.route}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('imageRegistry')}:</strong> <span>{apiMicroservice.image_repository_uri}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('deployedVersions')}:</strong>
              <ul>
                <li>{translate('dev')}: <pre className="d-inline">{microservice.dev_tag || translate('none')}</pre></li>
                <li>{translate('staging')}: <pre className="d-inline">{microservice.staging_tag || translate('none')}</pre></li>
                <li>{translate('prod')}: <pre className="d-inline">{microservice.prod_tag || translate('none')}</pre></li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <Configuration isDeveloperAuthorized={isDeveloperAuthorized} isSuperUserAuthorized={isSuperUser} microservice={microservice} />
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} canPromote={isDeveloperAuthorized} />
          </Tab>
          <Tab eventKey="logs" title={translate('logs')}>
            <LogsTab microservice={microservice} />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

ApiDetail.propTypes = {
  microservice: ApplicationMicroserviceApiPropTypes.isRequired,
};

export default ApiDetail;
