import { io } from 'socket.io-client';
import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({

    addApplicationMicroservice: builder.mutation({
      query: data => ({ url: 'application-microservices', method: 'post', data }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

    getApplicationMicroservice: builder.query({
      query: id => ({ url: `application-microservices/${id}`, method: 'get' }),
      providesTags: (result, error, id) => [{ type: 'ApplicationMicroservices', id }],
    }),

    listApplicationMicroservices: builder.query({
      query: queryParameters => {
        return { url: 'application-microservices', method: 'get', queryParameters };
      },
    }),

    getApplicationMicroserviceRoles: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/roles`, method: 'get', queryParameters };
      },
    }),

    editApplicationMicroservice: builder.mutation({
      query: data => ({ url: `application-microservices/${data.id}`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationMicroservices', id }],
    }),

    deleteApplicationMicroservice: builder.mutation({
      query: id => ({ url: `application-microservices/${id}`, method: 'delete' }),
      invalidatesTags: () => [{ type: 'ApplicationMicroservices' }],
    }),

    getApplicationMicroserviceJobs: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/jobs`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationMicroserviceJobs'],
    }),

    getApplicationMicroserviceDeployments: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/deployments`, method: 'get', queryParameters };
      },
      providesTags: ['ApplicationMicroserviceDeployments'],
    }),

    getApplicationMicroserviceImageTags: builder.query({
      query: ({ queryParameters, microserviceId }) => {
        return { url: `application-microservices/${microserviceId}/image-tags`, method: 'get', queryParameters };
      },
    }),

    promoteApplicationMicroserviceDeployment: builder.mutation({
      query: ({ deploymentId }) => ({ url: `deployments/promote/${deploymentId}`, method: 'post' }),
      invalidatesTags: () => [{ type: 'ApplicationMicroserviceDeployments' }],
    }),

    retryApplicationMicroserviceDeployment: builder.mutation({
      query: ({ deploymentId }) => ({ url: `deployments/retry/${deploymentId}`, method: 'post' }),
      invalidatesTags: () => [{ type: 'ApplicationMicroserviceDeployments' }],
    }),

    getApplicationMicroserviceEnvironmentVariables: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/environment-variables`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroserviceEnvironmentVariables'],
    }),

    editApplicationMicroserviceEnvironmentVariables: builder.mutation({
      query: ({ microserviceId, environmentVariables }) => ({
        url: `application-microservices/${microserviceId}/environment-variables`,
        method: 'post',
        data: { environment_variables: environmentVariables },
      }),
      invalidatesTags: ['ApplicationMicroserviceEnvironmentVariables'],
    }),

    getApplicationMicroserviceEventNotifications: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/event-notifications`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroserviceEventNotifications'],
    }),

    editApplicationMicroserviceEventNotifications: builder.mutation({
      query: ({ microserviceId, eventNotifications }) => ({
        url: `application-microservices/${microserviceId}/event-notifications`,
        method: 'post',
        data: { event_notifications: eventNotifications },
      }),
      invalidatesTags: ['ApplicationMicroserviceEventNotifications'],
    }),

    updateApplicationMicroserviceHttpPort: builder.mutation({
      query: data => ({ url: `application-microservices/${data.id}/http-port`, method: 'put', data }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ApplicationMicroservices', id }],
    }),

    deployApplicationMicroservice: builder.mutation({
      query: ({ microserviceId, environment, imageTag }) => ({
        url: `application-microservices/${microserviceId}/deploy`,
        method: 'post',
        data: { environment, image_tag: imageTag },
      }),
      invalidatesTags: () => [{ type: 'ApplicationMicroserviceDeployments' }],
    }),

    getApplicationMicroserviceKubernetesRoles: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/kubernetes-roles`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroserviceKubernetesRoles'],
    }),

    editApplicationMicroserviceKubernetesRoles: builder.mutation({
      query: ({ microserviceId, roles }) => ({
        url: `application-microservices/${microserviceId}/kubernetes-roles`,
        method: 'put',
        data: { roles: roles },
      }),
      invalidatesTags: ['ApplicationMicroserviceKubernetesRoles'],
    }),

    editMicroserviceS3Access: builder.mutation({
      query: ({ microserviceId, iamPolicyableMicroserviceIds }) => ({
        url: `application-microservices/${microserviceId}/grant-iam-access`,
        method: 'PUT',
        data: { iam_policyable_microservice_ids: iamPolicyableMicroserviceIds },
      }),
      invalidatesTags: () => [{ type: 'IamAccessibleMicroservices' }],
    }),

    getConsumesIamPolicyMicroservices: builder.query({
      query: ({ microserviceId }) => ({
        url: `application-microservices/${microserviceId}/iam-accessible-microservices`,
        method: 'GET',
      }),
      providesTags: ['IamAccessibleMicroservices'],
    }),

    getApplicationMicroservicePods: builder.query({
      query: ({ microserviceId }) => {
        return {
          url: `application-microservices/${microserviceId}/pods`,
          method: 'get',
        };
      },
      providesTags: ['ApplicationMicroservicePods'],
    }),

    getLogs: builder.query({
      // queryFn overrides the default query. We need to return some array here so that we can append to it on cache data update
      queryFn: async () => { return { data: [ 'establishing log connection...' ] } },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // create a websocket connection when the cache subscription starts
        const ws = io(`${window.location.origin}/?namespace=${arg.namespace}&podName=${arg.podName}&applicationId=${arg.applicationId}`, {
          path: '/proxy/logs',
        });

        ws.on('connect', () => {
          // emitting the logs event will trigger authentication and authorization to open a pod logs stream
          ws.emit('logs');
        });

        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event) => {
            // update cache with new event data
            updateCachedData((draft) => {
              draft.push(event);
            })
          }

          ws.on('message', listener);
        } catch {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscriptwsn is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
    }),
  }),
});

export const {
  useAddApplicationMicroserviceMutation,
  useLazyListApplicationMicroservicesQuery,
  useGetApplicationMicroserviceQuery,
  useLazyGetApplicationMicroserviceRolesQuery,
  useEditApplicationMicroserviceMutation,
  useDeleteApplicationMicroserviceMutation,
  useLazyGetApplicationMicroserviceJobsQuery,
  useLazyGetApplicationMicroserviceDeploymentsQuery,
  usePromoteApplicationMicroserviceDeploymentMutation,
  useRetryApplicationMicroserviceDeploymentMutation,
  useLazyGetApplicationMicroserviceEnvironmentVariablesQuery,
  useEditApplicationMicroserviceEnvironmentVariablesMutation,
  useLazyGetApplicationMicroserviceEventNotificationsQuery,
  useEditApplicationMicroserviceEventNotificationsMutation,
  useUpdateApplicationMicroserviceHttpPortMutation,
  useGetApplicationMicroserviceImageTagsQuery,
  useDeployApplicationMicroserviceMutation,
  useGetApplicationMicroserviceKubernetesRolesQuery,
  useEditApplicationMicroserviceKubernetesRolesMutation,
  useGetApplicationMicroservicePodsQuery,
  useLazyGetLogsQuery,
  useEditMicroserviceS3AccessMutation,
  useGetConsumesIamPolicyMicroservicesQuery,
} = extendedApiSlice;
