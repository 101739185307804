import React from 'react';
import PropTypes from 'prop-types';
import PaginationControl from 'common/PaginationControl';
import PaginationPropTypes from 'propTypes/PaginationPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';
import ApplicationMicroserviceDeploymentTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentTable';
import ApplicationMicroserviceDeploymentPropTypes from 'propTypes/application/ApplicationMicroserviceDeploymentPropTypes';

const ApplicationMicroserviceDeploymentPaginatedTable = ({
  applicationMicroserviceDeployments,
  pageCount,
  currentPage,
  onPageChange,
  sortBy,
  setSortBy,
  sortDirection,
  onUserSelected,
  actionColumn,
  isDbMicroservice,
  canPromote,
}) => (
  <>
    <ApplicationMicroserviceDeploymentTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      applicationMicroserviceDeployments={applicationMicroserviceDeployments}
      onUserSelected={onUserSelected}
      actionColumn={actionColumn}
      isDbMicroservice={isDbMicroservice}
      canPromote={canPromote}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

ApplicationMicroserviceDeploymentPaginatedTable.propTypes = {
  applicationMicroserviceDeployments: PropTypes.arrayOf(ApplicationMicroserviceDeploymentPropTypes),
  onUserSelected: PropTypes.func,
  canPromote: PropTypes.bool,
  actionColumn: ActionColumnPropTypes,
  ...PaginationPropTypes,
};

ApplicationMicroserviceDeploymentPaginatedTable.defaultProps = {
  applicationMicroserviceDeployments: [],
  onUserSelected: undefined,
  actionColumn: null,
  canPromote: false,
};

export default ApplicationMicroserviceDeploymentPaginatedTable;
