import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import { ApplicationMicroserviceScheduledJobPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import ScheduledJobConfiguration from './ScheduledJobConfiguration';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import GlobalSettings from 'App/AppRoutes/Applications/Application/Microservices/Detail/ScheduledJobDetail/GlobalSettings';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import LogsTab from 'common/computeBoundMicroservices/LogsTab';

const ScheduledJobDetail = ({ microservice }) => {
  const { microserviceable: schedJobMs } = microservice;
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);
  const { isSuperUser } = useCheckUserPermission();

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>
                {translate('scheduledJob')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('imageRegistry')}:</strong> <span>{schedJobMs.image_repository_uri}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <TabsWithNavigationHash
            defaultActiveKey="dev"
            transition={false}
            className="mb-3"
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey="dev" title={translate('dev')}>
              <ScheduledJobConfiguration env='dev' microservice={microservice} isDeveloperAuthorized={isDeveloperAuthorized} />
            </Tab>
            <Tab eventKey="staging" title={translate('staging')}>
              <ScheduledJobConfiguration env='staging' microservice={microservice} isDeveloperAuthorized={isDeveloperAuthorized} />
            </Tab>
            <Tab eventKey="prod" title={translate('prod')}>
              <ScheduledJobConfiguration env='prod' microservice={microservice} isDeveloperAuthorized={isDeveloperAuthorized} />
            </Tab>
            <Tab eventKey="globalSettings" title={translate('globalSettings')}>
              <GlobalSettings
                isDeveloperAuthorized={isDeveloperAuthorized}
                isSuperUserAuthorized={isSuperUser}
                microservice={microservice}
              />
            </Tab>
            <Tab eventKey="logs" title={translate('logs')}>
              <LogsTab microservice={microservice} />
            </Tab>
          </TabsWithNavigationHash>
        </Col>
      </Row>
    </Container>
  );
};

ScheduledJobDetail.propTypes = {
  microservice: ApplicationMicroserviceScheduledJobPropTypes.isRequired,
};

export default ScheduledJobDetail;
