import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import SearchNodeGroups from 'App/AppRoutes/Infrastructure/Dashboard/SearchNodeGroups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchKubernetesRoleConfigs from 'App/AppRoutes/Infrastructure/Dashboard/SearchKubernetesRoleConfigs';

const InfrastructureHome = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1>{translate('apexInfrastructure')}</h1>
        </Col>
      </Row>

      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="nodeGroups"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="nodeGroups" title={<span><FontAwesomeIcon icon={'police-box'} className="me-2" />{translate('nodeGroups')}</span>}>
            <SearchNodeGroups />
          </Tab>
          {/* @ts-expect-error this is a damn icon */}
          <Tab eventKey="kubernetes-roles" title={<span><FontAwesomeIcon icon={'person-to-portal'} className="me-2" />{translate('k8sRoleConfigs')}</span>}>
            <SearchKubernetesRoleConfigs />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

export default InfrastructureHome;
