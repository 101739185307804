import { Button, Col, Container, Row, Tab } from 'react-bootstrap';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Tile, Spinner, Error } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetApplicationQuery } from 'api/applicationSlice';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import Edit from 'App/AppRoutes/Applications/Application/Edit';
import ActionsDropdown from 'App/AppRoutes/Applications/Application/Detail/ActionsDropdown';
import AppHealthcheck from 'App/AppRoutes/Applications/Application/Detail/AppHealthcheck';
import ScheduledJobsCard from 'App/AppRoutes/Applications/Application/Detail/ScheduledJobsCard';
import Guides from 'App/AppRoutes/Applications/Application/Detail/Guides';
import OpenAppDropdown from 'App/AppRoutes/Applications/Application/Detail/OpenAppDropdown';
import RecentActivity from 'App/AppRoutes/Applications/Application/Detail/RecentActivity';
import Resources from 'App/AppRoutes/Applications/Application/Detail/Resources';
import Details from 'App/AppRoutes/Applications/Application/Detail/Details';
import ApplicationActivitySearchTable from 'common/applicationActivity/ApplicationActivitySearchTable';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

const Detail = () => {
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const { data: application, isLoading, error } = useGetApplicationQuery(applicationId as string);
  const { error: appAuthError, isDeveloperAuthorized, isSuperUser, isLoading: appAuthIsLoading } = useIsApplicationMaintainer(applicationId);

  if (isLoading || appAuthIsLoading) return (<Spinner size="10x" coverViewport />);
  if (error || appAuthError || !application) return (<Error coverPage />);

  return (
    <Container>
      <Row className="mt-4">
        <Col className="d-flex flex-start align-items-center">
          <h1 className="me-3">{application.name}</h1>

          <OpenAppDropdown
            devUrl={application.dev_url}
            stagingUrl={application.staging_url}
            prodUrl={application.prod_url}
          />
        </Col>

        <Col className="d-flex align-items-center justify-content-end">
          {
            isDeveloperAuthorized && (
              <Button
                className="me-3" onClick={() => navigate('microservices/new')}>
                <FontAwesomeIcon icon="plus" />
                {translate('addMicroservice')}
              </Button>
            )
          }
          {
            isSuperUser && (
              <ActionsDropdown application={application} />
            )
          }
        </Col>
      </Row>

      <Row>
        <Col>
          <p>{application.description}</p>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="overview"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="overview" title={translate('overview')}>
            <Row className="mt-3">
              <Col md={6} xs={12}>
                <AppHealthcheck application={application} />
                <ScheduledJobsCard application={application} />
                <Resources />
                <Guides />
              </Col>
              <Col md={6} xs={12}>
                <RecentActivity />
              </Col>
            </Row>
          </Tab>

          <Tab eventKey="activity" title={translate('activity')}>
            <Row className="mt-3">
              <ApplicationActivitySearchTable applicationId={application.id} applicationName={application.name} />
            </Row>
          </Tab>

          <Tab eventKey="accessManagement" title={translate('accessManagement')}>
            <Row>
              <Col md={3}>
                <Tile as={Link} to="maintainers" icon="robot-astromech" title={translate('maintainers')} variant="dark" />
              </Col>
              <Col md={3}>
                <Tile as={Link} to="groups" icon="user-group" title={translate('groups')} variant="dark" />
              </Col>
              <Col md={3}>
                <Tile as={Link} to="permissions" icon="user-unlock" title={translate('permissions')} variant="dark" />
              </Col>
              <Col md={3}>
                <Tile as={Link} to="roles" icon="user-cowboy" title={translate('roles')} variant="dark" />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={3}>
                <Tile as={Link} to="organizations" icon="sitemap" title={translate('organizations')} variant="dark" />
              </Col>
            </Row>
          </Tab>
          {isDeveloperAuthorized && (
            <Tab eventKey="details" title={translate('details')}>
              <Details application={application} />
            </Tab>
          )}
          {isSuperUser && (
            <Tab eventKey="settings" title={translate('settings')}>
              <Row className="mt-3">
                <Edit />
              </Row>
            </Tab>
          )}
        </TabsWithNavigationHash>
      </Row>
    </Container >
  );
};

export default Detail;
