import React, { useContext } from 'react';

import { AccordionContext, Button, useAccordionButton, } from 'react-bootstrap';

const AccordionToggleLink = ({ eventKey, activeText, inactiveText }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  const { activeEventKey } = useContext(AccordionContext);

  const isActive = activeEventKey && activeEventKey.includes(eventKey);

  return (
    <Button
      variant="link"
      onClick={decoratedOnClick}
    >
      {isActive ? activeText : inactiveText}
    </Button>
  );
};

export default AccordionToggleLink;
