import React from 'react';
import { translate } from '@apex/react-toolkit/lib';
import AccordionToggleLink from 'common/AccordionToggleLink';
import ApplicationMicroserviceEnvironmentVariables from 'common/applicationMicroservice/ApplicationMicroserviceEnvironmentVariables';
import { Accordion, Badge, Card, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { IApplicationMicroserviceWorker } from 'types/application/microservice/IWorkerMicroservice';
import NetworkConfiguration from './NetworkConfiguration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComputeDetails from 'App/AppRoutes/Applications/Application/Microservices/Detail/WorkerDetail/Configuration/ComputeDetails';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';

const Configuration: React.FC<{
  microservice: IApplicationMicroserviceWorker;
  isDeveloperAuthorized: boolean;
  isSuperUserAuthorized: boolean;
}> = ({
  microservice,
  isDeveloperAuthorized,
  isSuperUserAuthorized,
}) => {
    return (
      <Accordion alwaysOpen>
        <Card bg="dark">
          <Card.Header>
            <Row>
              <Col className="text-start">
                {translate('environmentVariables')}
              </Col>
              <Col>
                <span className="text-muted">
                  {microservice.environment_variables_count} {translate('environmentVariables')}
                </span>
              </Col>
              <Col className="text-end">
                <AccordionToggleLink
                  eventKey="0"
                  inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                  activeText={translate('close')}
                />
              </Col>
            </Row>
          </Card.Header>
        </Card>
        <Accordion.Collapse eventKey="0">
          <ApplicationMicroserviceEnvironmentVariables microserviceId={microservice.id} />
        </Accordion.Collapse>
        <Card bg="dark" className="mt-3">
          <Card.Header>
            <Row>
              <Col className="text-start">
                {translate('networkConfiguration')}
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="right"
                  rootClose
                  overlay={(
                    <Popover>
                      <Popover.Body>
                        {translate('workerNetworkPortExplanation')}
                      </Popover.Body>
                    </Popover>
                  )}
                >
                  <Badge pill bg="secondary" className="ms-2 h-50 my-auto text-center">
                    <FontAwesomeIcon icon="info" />
                  </Badge>
                </OverlayTrigger>
              </Col>
              <Col>
                <span className="text-muted">
                  {
                    microservice.microserviceable.network_port ?
                      translate('acceptingTrafficOnPort', {
                        port: microservice.microserviceable.network_port
                      })
                      :
                      translate('notAcceptingTraffic')
                  }
                </span>
              </Col>
              <Col className="text-end">
                <AccordionToggleLink
                  eventKey="1"
                  inactiveText="Edit"
                  activeText={translate('close')}
                />
              </Col>
            </Row>
          </Card.Header>
        </Card>
        <Accordion.Collapse eventKey="1">
          <NetworkConfiguration microservice={microservice} isDeveloperAuthorized={isDeveloperAuthorized} />
        </Accordion.Collapse>
        <div>
          <Card bg="dark" className="mt-3">
            <Card.Header>
              <Row>
                <Col className="text-start">
                  {translate('computeDetails')}
                </Col>
                <Col>
                  <span className="text-muted">
                    {microservice.microserviceable.instance_type}
                  </span>
                </Col>
                <Col className="text-end">
                  <AccordionToggleLink
                    eventKey="2"
                    inactiveText={isDeveloperAuthorized ? translate('edit') : translate('view')}
                    activeText={translate('close')}
                  />
                </Col>
              </Row>
            </Card.Header>
          </Card>
          <Accordion.Collapse eventKey="2">
            <ComputeDetails microservice={microservice} isDeveloperAuthorized={isDeveloperAuthorized} />
          </Accordion.Collapse>
        </div>
        { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }
      </Accordion>
    );
  };

export default Configuration;
