import React from 'react';
import PropTypes from 'prop-types';
import PaginationControl from 'common/PaginationControl';
import PaginationPropTypes from 'propTypes/PaginationPropTypes';
import UserTable from 'common/user/UserTable';
import UserPropTypes from 'propTypes/user/UserPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';

const UserPaginatedTable = ({ users, pageCount, currentPage, onPageChange, sortBy, setSortBy, sortDirection, onUserSelected, actionColumn, isFetching, approvalColumn=true }) => (
  <>
    <UserTable
      sortBy={sortBy}
      setSortBy={setSortBy}
      sortDirection={sortDirection}
      users={users}
      onUserSelected={onUserSelected}
      actionColumn={actionColumn}
      isFetching={isFetching}
      approvalColumn={approvalColumn}
    />
    <PaginationControl
      pagesToShow={3}
      pageCount={pageCount}
      currentPage={currentPage}
      onPageChange={onPageChange}
    />
  </>
);

UserPaginatedTable.propTypes = {
  users: PropTypes.arrayOf(UserPropTypes),
  onUserSelected: PropTypes.func,
  actionColumn: ActionColumnPropTypes,
  isFetching: PropTypes.bool,
  ...PaginationPropTypes,
};

UserPaginatedTable.defaultProps = {
  users: [],
  onUserSelected: undefined,
  actionColumn: null,
  isFetching: false,
};

export default UserPaginatedTable;
