import { useDispatch } from 'react-redux';
import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  PlatformToolbar,
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import 'localization';
import { translate } from '@apex/react-toolkit/lib';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { setToken, clearToken, setUser, clearUser } from 'localState/authSlice';
import AppRoutes from 'App/AppRoutes';
import Toasts from 'App/Toasts';
import ErrorBoundary from 'common/ErrorBoundary';
import Settings from 'common/Settings';
import { useIsAuthorizedForEnvironmentQuery } from 'api/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import IUser from 'types/IUser';

const App = () => {
  const dispatch = useDispatch();
  // @ts-expect-error TODO: Figure out why TS thinks `useIsAuthorizedForEnvironmentQuery()` requires an argument.
  const { isLoading, error } = useIsAuthorizedForEnvironmentQuery();
  const { isSuperUser } = useCheckUserPermission();

  let content;

  if (error) content = <Error error={error} />;
  else if (isLoading) content = <Spinner coverViewport />;
  else content = <AppRoutes />;

  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <Container fluid>
                <PlatformToolbar
                  name="Apex Admin"
                  icon={<img src="/favicon-32x32.png" className="mb-1" style={{ height: '20px' }} />}
                  navigationItems={[
                    <Nav.Link
                      as={Link}
                      to="users"
                      className="text-white"
                      role="button"
                    >
                      <FontAwesomeIcon icon="user" style={{ width: "2rem" }} />
                      <span className="ms-2">{translate("users")}</span>
                    </Nav.Link>,
                    <Nav.Link
                      as={Link}
                      to="groups"
                      className="text-white"
                      role="button"
                    >
                      <FontAwesomeIcon icon="users" style={{ width: "2rem" }} />
                      <span className="ms-2">{translate("groups")}</span>
                    </Nav.Link>,
                    <Nav.Link
                      as={Link}
                      to="applications"
                      className="text-white"
                      role="button"
                    >
                      <FontAwesomeIcon
                        icon="browser"
                        style={{ width: "2rem" }}
                      />
                      <span className="ms-2">{translate("applications")}</span>
                    </Nav.Link>,
                    <Nav.Link
                      as={Link}
                      to="organizations"
                      className="text-white"
                      role="button"
                    >
                      <FontAwesomeIcon
                        icon="sitemap"
                        style={{ width: "2rem" }}
                      />
                      <span className="ms-2">{translate("organizations")}</span>
                    </Nav.Link>,
                    isSuperUser && (
                      <Nav.Link
                      as={Link}
                      to="infrastructure"
                      className="text-white"
                      role="button"
                    >
                      <FontAwesomeIcon
                        // @ts-expect-error cloud-binary is a valid icon, but isn't listed in FontAwesome types
                        icon="cloud-binary"
                        style={{ width: "2rem" }}
                      />
                      <span className="ms-2">{translate("apexInfrastructure")}</span>
                    </Nav.Link>
                    ),
                  ]}
                  statusIcons={[]}
                  settingsComponent={<Settings />}
                  onFreshUser={(freshUser: IUser) => dispatch(setUser(freshUser))}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onFreshToken={(freshToken: any) =>
                    dispatch(setToken(freshToken))
                  }
                  onLogOut={() => {
                    clearToken();
                    clearUser();
                  }}
                />
                <Row>
                  <Col>
                    <ErrorBoundary>
                      <Toasts />
                      {content}
                    </ErrorBoundary>
                  </Col>
                </Row>
              </Container>
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
