import apiSlice from './apiSlice';
import ISeries from 'types/series/ISeries';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    listSeries: builder.query<ISeries[], void>({
      query: () => {
        return { url: 'series', method: 'get' };
      },
      providesTags: ['Series'],
    })
  })
});

export const {
  useListSeriesQuery,
} = extendedApiSlice;
