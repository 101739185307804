import { DateTime } from 'luxon';

export const formatDate = (dateString) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_SHORT);
};

export const formatTime = (dateString) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.TIME_SIMPLE);
};

export const formatDateTime = (dateString) => {
  return DateTime.fromISO(dateString).toLocaleString(DateTime.DATETIME_SHORT);
};

/**
 * Take an ISO String with zulu time and get a local time formatted specifically for datetime-local formcontrols
 *
 * @param string isoString
 * @returns string
 */
export const formControlIsoToLocalIn = (isoString) => {
  return DateTime.fromISO(isoString).toFormat('yyyy-MM-dd__HH:mm').replace('__', 'T').replace(/\.[0-9Z]+$/, '');
};

/**
 * Take a local datetime form control value, and convert it to ISO string with zulu time
 *
 * @param string formcontrolVal
 * @returns string
 */
export const formControlLocalToIsoOut = (formcontrolVal) => {
  return DateTime.fromFormat(formcontrolVal.replace('T', '__'), 'yyyy-MM-dd__HH:mm').toUTC().toISO();
};
