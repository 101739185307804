import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';
import ApplicationActivityPropTypes from 'propTypes/application/ApplicationActivityPropTypes';
import { formatDateTime } from 'helpers/datetime';
import { isValidSemVer } from 'helpers/semver';
import { useRetryApplicationMicroserviceDeploymentMutation } from 'api/applicationMicroserviceSlice';
import ConfirmPromotionModal from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentTable/ConfirmPromotionModal';
import ApplicationActivityStatus from 'common/applicationActivity/ApplicationActivityStatus';
import { useParams } from 'react-router-dom';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';

const ApplicationActivityTable = ({ applicationActivities, sortBy, setSortBy, sortDirection }) => {
  const { applicationId } = useParams();
  const [retryDeployment, { isLoading }] = useRetryApplicationMicroserviceDeploymentMutation();
  const [showConfirmPromotionModal, setShowConfirmPromotionModal] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState({ deploymentId: null, imageTag: null, targetEnvironment: null });
  const { isDeveloperAuthorized, isFetching: appAuthIsFetching } = useIsApplicationMaintainer(applicationId);

  const columns = [
    {
      title: translate('timestamp'),
      dataIndex: 'updated_at',
      sortDirection: sortBy === 'updated_at' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('updated_at'),
        className: 'clickable',
      }),
      render: updatedAt => {
        return formatDateTime(updatedAt);
      },
    },
    {
      title: translate('name'),
      dataIndex: 'name',
      sortDirection: sortBy === 'name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('name'),
        className: 'clickable',
      }),
    },
    {
      title: translate('environment'),
      dataIndex: 'environment',
      sortDirection: sortBy === 'environment' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('environment'),
        className: 'clickable',
      }),
      render: environment => {
        if (!environment) return null; // Application-level jobs
        return translate(environment);
      },
    },
    {
      title: translate('activity'),
      dataIndex: 'action',
      sortDirection: sortBy === 'action' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('action'),
        className: 'clickable',
      }),
      render: (action, activity) => {
        return (
          <>
            <span className="p-1">
              <ApplicationActivityStatus status={activity.status} />
            </span>
            <span className="p-1">
              {translate(action)}
            </span>
            <span className="p-1">
              <code>{activity.code_identifier}</code>
            </span>
          </>
        );
      },
    },
  ];

  if (!appAuthIsFetching && isDeveloperAuthorized) {
    columns.push({
      title: 'Actions',
      render: (_, deployment) => {
        const buttons = [];

        const eligibleForPromotion = deployment.status === 'COMPLETE'
          && (['dev', 'staging'].includes(deployment.environment))
          && (isValidSemVer(deployment.code_identifier) || ['database', 's3'].includes(deployment.microservice_type));

        if (eligibleForPromotion) {
          buttons.push(
            <Button
              size="sm"
              className="me-2"
              onClick={() => {
                setPromotionDetails({
                  deploymentId: deployment.id,
                  imageTag: deployment.code_identifier || deployment.engine_version,
                });
                setShowConfirmPromotionModal(true);
              }}
            >
              <FontAwesomeIcon icon="rocket-launch" />
              {translate('promote')}
            </Button>
          );
        }

        if (!['CREATED', 'IN_PROGRESS'].includes(deployment.status)) {
          buttons.push(
            <Button
              variant="secondary"
              size="sm"
              onClick={() => retryDeployment({ deploymentId: deployment.id })}
              disabled={isLoading || deployment.status === 'IN_PROGRESS'}
            >
              <FontAwesomeIcon icon="arrows-rotate" fixedWidth />
              {translate('retry')}
            </Button>
          );
        }

        if (buttons.length === 0) return null;

        return (
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={(
              <Popover>
                <Popover.Body>
                  {buttons}
                </Popover.Body>
              </Popover>
            )}
          >
            <Button
              variant="link"
              style={{ color: 'white' }}
            >
              <FontAwesomeIcon icon="ellipsis-vertical" />
            </Button>
          </OverlayTrigger>
        );
      },
    });
  }

  return (
    <>
      <InteractiveTable
        dataId="id"
        data={applicationActivities}
        columns={columns}
        sortDirection={sortDirection}
      />
      <ConfirmPromotionModal
        show={showConfirmPromotionModal}
        handleClose={() => setShowConfirmPromotionModal(false)}
        deploymentId={promotionDetails.deploymentId}
        imageTag={promotionDetails.imageTag}
        targetEnvironment={promotionDetails.targetEnvironment}
      />
    </>
  );
};

ApplicationActivityTable.propTypes = {
  ...SortPropTypes,
  applicationActivities: PropTypes.arrayOf(ApplicationActivityPropTypes).isRequired,
};

export default ApplicationActivityTable;
