import { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { IApplicationMicroserviceScheduledJob } from 'types/application/microservice/IScheduledJobMicroservice';
import MicroserviceKubernetesRoleConfigAccordion from 'common/infrastructure/roleConfigs/MicorserviceRoleConfigAccordion';

const GlobalSettings: FC<{
  microservice: IApplicationMicroserviceScheduledJob;
  isDeveloperAuthorized: boolean;
  isSuperUserAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized, isSuperUserAuthorized }) => {
  return (
    <Accordion alwaysOpen>
      { // Kubernetes Roles
        isDeveloperAuthorized && (
          <MicroserviceKubernetesRoleConfigAccordion
            isSuperUserAuthorized={isSuperUserAuthorized}
            microserviceId={microservice.id}
          />
        )
      }
    </Accordion>
  )
};

export default GlobalSettings;
