import React, { useContext } from 'react';
import { Accordion, AccordionContext, Button, Card, Col, Row, useAccordionButton } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { camelCase } from 'lodash';
import CopyableLineItem from 'common/copyableLineItem';
import IDatabaseMicroservice, { IDatabaseMicroserviceMetadata } from 'types/application/microservice/IDatabaseMicroservice';
import Environment from 'types/Environment';
import TrustedSources from 'common/applicationMicroservice/relationalDatabase/RelationalDatabaseConnectionDetails/TrustedSources';

type AccordingToggleLinkProps = {
  eventKey: string;
  activeText: string;
  inactiveText: string;
};

const AccordionToggleLink = ({ eventKey, activeText, inactiveText }: AccordingToggleLinkProps) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  const { activeEventKey } = useContext(AccordionContext);

  const isActive = activeEventKey && activeEventKey.includes(eventKey);

  return (
    <Button
      variant="link"
      onClick={decoratedOnClick}
    >
      {isActive ? activeText : inactiveText}
    </Button>
  );
};

const RelationalDatabaseConnectionDetails: React.FC<{
  environment: Environment;
  instance: IDatabaseMicroserviceMetadata;
  engine: string;
  engineVersion: string;
  dbMicroservice: IDatabaseMicroservice;
}> = ({ environment, instance, engine, engineVersion, dbMicroservice }) => {
  return (
    <div className="mb-4">
      <Card bg="dark">
        <Card.Header>
          <Row>
            <Col md="3" className="text-start">
              {`${translate(environment)} ${translate('databaseConnectionDetails')}`}
            </Col>
            <Col>
              {
                instance.status ?
                  <span className="text-muted me-4">{engine} {engineVersion}: {instance.status}</span> :
                  <>
                    <span className="text-muted me-1">{engine} {engineVersion}:</span>
                    <span className="text-danger me-4">{translate('dbStatusNotAvailable')}</span>
                  </>
              }
            </Col>
            <Col className="text-end">
              <AccordionToggleLink
                eventKey={environment}
                inactiveText="View"
                activeText="Close"
              />
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Accordion.Collapse eventKey={environment}>
        <Card bg="dark">
          <Card.Body>
            <Row>
              <Col>
                {
                  Object.keys(instance as IDatabaseMicroserviceMetadata)
                    .filter(field => field !== 'status')
                    .map((field: string) => {
                      return (
                        field && <CopyableLineItem
                          key={field}
                          field={translate(camelCase(field))}
                          value={instance[field as keyof IDatabaseMicroserviceMetadata]?.toString() || `${field} ${translate('notAvailableYet')}`}
                          isReady={!!instance[field as keyof IDatabaseMicroserviceMetadata]}
                          buttonVariant="link"
                          buttonSize="sm"
                          secureField={field === 'master_password'}
                        />
                      );
                    })
                }
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <TrustedSources
                  environment={environment}
                  dbMicroservice={dbMicroservice}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Accordion.Collapse>
    </div>
  );
};

export default RelationalDatabaseConnectionDetails;
