import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import ApplicationMicroserviceHealthCheckStatus from 'common/applicationMicroservice/ApplicationMicroserviceHealthCheckStatus';
import { ApplicationMicroserviceApiPropTypes } from 'propTypes/application/microservice/ApplicationMicroservicePropTypes';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useHealthcheck from 'hooks/useHealthcheck';
import styles from './UiDetail.module.css';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import ConfigurationTab from './ConfigurationTab';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import LogsTab from 'common/computeBoundMicroservices/LogsTab';

const UiDetail = ({ microservice }) => {
  const { microserviceable: uiMicroservice } = microservice;
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);
  const { isSuperUser } = useCheckUserPermission();

  const isHealthy = useHealthcheck(microservice.id, 'prod');

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
                {(isHealthy !== null) && (
                  <span className={`ms-3 align-middle ${styles.healthCheckStatusContainer}`}>
                    <ApplicationMicroserviceHealthCheckStatus isHealthy={isHealthy} />
                  </span>
                )}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('url')}:</strong>
              <a target="_blank" href={`https://${uiMicroservice.url}`} rel="noreferrer" className="ms-3">
                {uiMicroservice.url}
                <FontAwesomeIcon icon="arrow-up-right-from-square" fixedWidth />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('imageRegistry')}:</strong> <span>{uiMicroservice.image_repository_uri}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('deployedVersions')}:</strong>
              <ul>
                <li>{translate('dev')}: <pre className="d-inline">{microservice.dev_tag}</pre></li>
                <li>{translate('staging')}: <pre className="d-inline">{microservice.staging_tag || translate('none')}</pre></li>
                <li>{translate('prod')}: <pre className="d-inline">{microservice.prod_tag || translate('none')}</pre></li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <ConfigurationTab
              microservice={microservice}
              isDeveloperAuthorized={isDeveloperAuthorized}
              isSuperUserAuthorized={isSuperUser}
            />
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} canPromote={isDeveloperAuthorized} />
          </Tab>
          <Tab eventKey="logs" title={translate('logs')}>
            <LogsTab microservice={microservice} />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

UiDetail.propTypes = {
  microservice: ApplicationMicroserviceApiPropTypes.isRequired,
};

export default UiDetail;
