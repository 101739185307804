import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import RedisMicroserviceConfiguration from 'common/applicationMicroservice/redis/RedisMicroserviceConfiguration';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import { IApplicationMicroserviceRedis } from 'types/application/microservice/IRedisMicroservice';

const RedisDetail: React.FC<{
  microservice: IApplicationMicroserviceRedis;
}> = ({ microservice }) => {
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>{microservice.name}</h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <RedisMicroserviceConfiguration microservice={microservice} isDeveloperAuthorized={isDeveloperAuthorized} />
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} isDbMicroservice canPromote={isDeveloperAuthorized} />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

export default RedisDetail;
