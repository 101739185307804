import { useGetApplicationAccessQuery, useGetUserQuery } from 'api/userSlice';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationListItem from 'App/AppRoutes/Organizations/Detail/ApplicationsTab/ApplicationListItem';
import UserRoleDetailPanel from 'App/AppRoutes/Organizations/Detail/User/UserRoleDetailPanel';
import IApplication from 'types/application/IApplication';
import IApplicationRole from 'types/application/IApplicationRole';

const User = () => {
  const { userId } = useParams();
  const {
    data: user,
    isLoading: userLoading,
    error: userError,
  } = useGetUserQuery({ id: userId as string, queryParameters: { relationships: 'organizations' } });
  const {
    data: appAccess,
    isLoading: appAccessLoading,
    error: appAccessError
  } = useGetApplicationAccessQuery(userId as string);

  const [selected, setSelected] = useState<IApplication | null>(null);
  const [selectedRole, setSelectedRole] = useState<IApplicationRole | null>(null);

  useEffect(() => {
    if (appAccess && appAccess.length > 0) {
      setSelected(appAccess[0]);

      if (appAccess[0].roles && appAccess[0].roles.length > 0) {
        setSelectedRole(appAccess[0].roles[0]);
      }
    }
  }, [appAccess, setSelected, setSelectedRole]);

  if (appAccessLoading || userLoading) return (<Spinner />);
  if (appAccessError || userError || !user) return (<Error />);

  return (
    <Container className="mb-4">
      <Row className="mt-4">
        <Col>
          <h1>{user.first_name} {user.last_name}</h1>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h2>User Details</h2>
        </Col>
      </Row>
      <Card bg="dark">
        <Card.Body>
          <Row className="mt-1">
            <Col md={4}>
              <Row>
                <Col><strong>{translate('firstName')}:</strong> {user.first_name}</Col>
              </Row>
              <Row>
                <Col><strong>{translate('lastName')}:</strong> {user.last_name}</Col>
              </Row>
              <Row>
                <Col><strong>{translate('email')}:</strong> {user.email}</Col>
              </Row>
              <Row>
                <Col>
                  <strong>{translate('organization')}:</strong> {user.organization?.name}
                  {/* @ts-expect-error asdf */}
                  <span className="fst-italic"> ({user.organization?.pivot.role})</span>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Row>
                <Col><strong>{translate('createdAt')}:</strong> {new Date(user.created_at).toLocaleString()}</Col>
              </Row>
              <Row>
                <Col><strong>{translate('updatedAt')}:</strong> {new Date(user.updated_at).toLocaleString()}</Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row className="mt-4">
        <Col>
          <h2>Application Access</h2>
        </Col>
      </Row>
      <Row className='mt-1'>
        <Row>
          <Col>
            {
              appAccess && appAccess.map((application: IApplication) => (
                <ApplicationListItem
                  key={application.id}
                  application={application}
                  setSelected={(application) => {
                    setSelected(application);
                    setSelectedRole(application.roles ? application.roles[0] : null);
                  }}
                  isSelected={selected === application}
                />
              ))
            }
          </Col>
          {
            selected && (
              <UserRoleDetailPanel
                appRoles={selected?.roles || []}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
              />
            )
          }
        </Row>
      </Row>
    </Container>
  );
};

export default User;
