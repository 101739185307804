import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from '@apex/react-toolkit/lib';
import InteractiveTable from 'common/InteractiveTable';
import UserPropTypes from 'propTypes/user/UserPropTypes';
import { SortPropTypes } from 'propTypes/table/SortPropTypes';
import ActionColumnPropTypes from 'propTypes/table/ActionColumnPropTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEditUserMutation } from 'api/userSlice';
import { selectCurrentUser } from 'localState/authSlice';

const UserTable = ({ users, sortBy, setSortBy, sortDirection, actionColumn, isFetching, approvalColumn=true }) => {
  const [updateUser, { isLoading }] = useEditUserMutation();
  const currentUser = useSelector(selectCurrentUser);

  const toggleApproved = (user) => updateUser({
    ...user,
    approved: !user.approved,
  });

  const columns = [
    {
      title: translate('firstName'),
      dataIndex: 'first_name',
      sortDirection: sortBy === 'first_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('first_name'),
        className: 'clickable',
      }),
      render: (first, { id }) => (<Link to={`/users/${id}`}>{first}</Link>),
    },
    {
      title: translate('lastName'),
      dataIndex: 'last_name',
      sortDirection: sortBy === 'last_name' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('last_name'),
        className: 'clickable',
      }),
    },
    {
      title: translate('email'),
      dataIndex: 'email',
      sortDirection: sortBy === 'email' && sortDirection,
      onHeader: () => ({
        onClick: () => setSortBy('email'),
        className: 'clickable',
      }),
    },
  ];

  if (actionColumn) columns.push(actionColumn);

  if (approvalColumn) columns.push({
    title: translate('approved'),
    dataIndex: 'approved',
    sortDirection: sortBy === 'approved' && sortDirection,
    onTd: () => ({ className: 'text-center' }),
    render: (approved, user) => {
      return (
        <DropdownButton
          as={ButtonGroup}
          size="sm"
          variant={approved ? 'success' : 'danger'}
          disabled={isFetching || isLoading || user.id === currentUser.id}
          title={
            // eslint-disable-next-line no-nested-ternary
            (isFetching || isLoading) ? `${translate('loading')}...`
              : (approved ? translate('approved') : translate('unapproved'))
          }
        >
          <Dropdown.Item disabled={approved || isLoading} onClick={() => toggleApproved(user)}>
            Approve {!approved && (<FontAwesomeIcon style={{ color: 'green' }} icon="circle-check" />)}
          </Dropdown.Item>
          <Dropdown.Item disabled={!approved || isLoading} onClick={() => toggleApproved(user)}>
            Unapprove  {approved && (<FontAwesomeIcon style={{ color: 'red' }} icon="circle-xmark" />)}
          </Dropdown.Item>
        </DropdownButton>
      );
    },
  });

  return (
    <InteractiveTable
      dataId="id"
      data={users}
      columns={columns}
      sortDirection={sortDirection}
    />
  );
};

UserTable.defaultProps = {
  actionColumn: null,
  isFetching: false,
};

UserTable.propTypes = {
  ...SortPropTypes,
  actionColumn: ActionColumnPropTypes,
  users: PropTypes.arrayOf(UserPropTypes).isRequired,
  isFetching: PropTypes.bool,
};

export default UserTable;
