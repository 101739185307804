import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Spinner,
  Error,
} from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { useGetApplicationMicroserviceQuery } from 'api/applicationMicroserviceSlice';
import ApiDetail from './ApiDetail';
import AuthDetail from './AuthDetail';
import RelationalDatabaseDetail from './RelationalDatabaseDetail';
import HasuraDetail from './HasuraDetail';
import LambdaDetail from './LambdaDetail';
import RedisDetail from './RedisDetail';
import S3Detail from './S3Detail';
import ScheduledJobDetail from './ScheduledJobDetail';
import UiDetail from './UiDetail';
import WorkerDetail from './WorkerDetail';

const Detail = () => {
  const { microserviceId } = useParams();
  const { data: microservice, isFetching, error } = useGetApplicationMicroserviceQuery(microserviceId);

  if (error) return <Error coverPage />;
  if (isFetching && !microservice) return <Spinner size="10x" coverViewport />;

  switch (microservice.application_microserviceable_type) {
    case 'api': return <ApiDetail microservice={microservice} />;
    case 'auth': return <AuthDetail microservice={microservice} />;
    case 'database': return <RelationalDatabaseDetail microservice={microservice} />;
    case 'hasura': return <HasuraDetail microservice={microservice} />;
    case 'lambda': return <LambdaDetail microservice={microservice} />;
    case 'redis': return <RedisDetail microservice={microservice} />;
    case 's3': return <S3Detail microservice={microservice} />;
    case 'scheduled_job': return <ScheduledJobDetail microservice={microservice} />;
    case 'ui': return <UiDetail microservice={microservice} />;
    case 'worker': return <WorkerDetail microservice={microservice} />;
    default: return <p>{translate('notImplemented')}</p>;
  }
};

export default Detail;
