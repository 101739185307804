import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { translate } from '@apex/react-toolkit/lib';
import ApplicationMicroserviceJobSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceJobSearchTable';
import ApplicationMicroserviceDeploymentSearchTable from 'common/applicationMicroservice/ApplicationMicroserviceDeploymentSearchTable';
import TabsWithNavigationHash from 'common/TabsWithNavigationHash';
import useIsApplicationMaintainer from 'hooks/useIsApplicationMaintainer';
import { IApplicationMicroserviceWorker } from 'types/application/microservice/IWorkerMicroservice';
import Configuration from './Configuration';
import useCheckUserPermission from 'hooks/useCheckUserPermission';
import LogsTab from 'common/computeBoundMicroservices/LogsTab';

const WorkerDetail: React.FC<{
  microservice: IApplicationMicroserviceWorker
}> = ({ microservice }) => {
  const { microserviceable: workerMicroservice } = microservice;
  const { applicationId } = useParams();
  const { isDeveloperAuthorized } = useIsApplicationMaintainer(applicationId);
  const { isSuperUser } = useCheckUserPermission();

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <Row>
            <Col>
              <h1>
                {microservice.name}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>{translate('worker')}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('imageRegistry')}:</strong> <span>{workerMicroservice.image_repository_uri}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>{translate('deployedVersions')}:</strong>
              <ul>
                <li>{translate('dev')}: <pre className="d-inline">{microservice.dev_tag}</pre></li>
                <li>{translate('staging')}: <pre className="d-inline">{microservice.staging_tag || translate('none')}</pre></li>
                <li>{translate('prod')}: <pre className="d-inline">{microservice.prod_tag || translate('none')}</pre></li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <TabsWithNavigationHash
          defaultActiveKey="configuration"
          transition={false}
          className="mb-3"
          mountOnEnter
          unmountOnExit
        >
          <Tab eventKey="configuration" title={translate('configuration')}>
            <Configuration
              microservice={microservice}
              isDeveloperAuthorized={isDeveloperAuthorized}
              isSuperUserAuthorized={isSuperUser}
            />
          </Tab>
          <Tab eventKey="jobs" title={translate('jobs')}>
            <ApplicationMicroserviceJobSearchTable microserviceId={microservice.id} />
          </Tab>
          <Tab eventKey="deployments" title={translate('deployments')}>
            <ApplicationMicroserviceDeploymentSearchTable microserviceId={microservice.id} canPromote={isDeveloperAuthorized} />
          </Tab>
          <Tab eventKey="logs" title={translate('logs')}>
            <LogsTab microservice={microservice} />
          </Tab>
        </TabsWithNavigationHash>
      </Row>
    </Container>
  );
};

export default WorkerDetail;
