import PropTypes from 'prop-types';
import BaseModelPropTypes from 'propTypes/BaseModelPropTypes';

export const applicationMicroserviceDeploymentProperties = {
  ...BaseModelPropTypes,
};

export default PropTypes.shape({
  ...applicationMicroserviceDeploymentProperties,
  isDbMicroservice: PropTypes.bool.isRequired,
});
