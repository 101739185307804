import { FC, useState } from 'react';
import { Button, Card, Col, FormControl, FormSelect, Row } from 'react-bootstrap';
import { Error, Spinner } from '@apex/react-toolkit/components';
import { translate } from '@apex/react-toolkit/lib';
import { IApplicationMicroserviceWorker } from 'types/application/microservice/IWorkerMicroservice';
import { useGetNodeGroupTypesQuery } from 'api/nodeGroupSlice';
import { useUpdateWorkerMicroserviceMutation } from 'api/workerMicroserviceSlice';

const ComputeDetails: FC<{
  microservice: IApplicationMicroserviceWorker;
  isDeveloperAuthorized: boolean;
}> = ({ microservice, isDeveloperAuthorized }) => {
  const [updateWorker, { isLoading: updateLoading }] = useUpdateWorkerMicroserviceMutation();
  const worker = microservice.microserviceable;
  const [formInstanceType, setFormInstanceType] = useState<string>(worker.instance_type);
  const updateEnabled = microservice.microserviceable.instance_type !== formInstanceType;
  const { data: nodeGroupTypes, isLoading: nodeGroupTypesLoading, error: nodeGroupTypesError } = useGetNodeGroupTypesQuery();

  const isLoading = nodeGroupTypesLoading || updateLoading;
  if (nodeGroupTypesError) return <Error coverPage />;
  if (!nodeGroupTypes || nodeGroupTypesLoading) return <Spinner />;

  return (
    <Card bg="dark">
      <Card.Body>
        <Row>
          <Col>
            <h5>{translate('instanceType')}</h5>
          </Col>
        </Row>
        <Row>
          {
            isDeveloperAuthorized ? (
              <>
                <Col>
                  <FormSelect
                    name="instanceType"
                    placeholder={translate('instanceType')}
                    value={formInstanceType}
                    disabled={isLoading}
                    onChange={(e) => setFormInstanceType(e.target.value)}
                  >
                    <option key="noSelection" value="General Purpose">
                      {translate('generalPurpose')}
                    </option>
                    {
                      nodeGroupTypes.map(type => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))
                    }
                  </FormSelect>
                </Col>
                <Col>
                  <Button
                    disabled={!updateEnabled || isLoading}
                    variant="primary"
                    className="me-2"
                    onClick={() => {
                      updateWorker({
                        ...worker,
                        instance_type: formInstanceType,
                      });
                    }}>
                    {translate('update')}
                  </Button>
                  <Button
                    disabled={!updateEnabled || isLoading}
                    variant="danger"
                    onClick={async () => {
                      setFormInstanceType(microservice.microserviceable.instance_type);
                    }}>
                    {translate('cancel')}
                  </Button>
                </Col>
              </>
            ) : (
              <Col>
                <FormControl
                  readOnly
                  type="text"
                  value={microservice.microserviceable.instance_type}
                />
              </Col>
            )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ComputeDetails;
